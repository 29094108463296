import {

    LOADING
   
} from '../actionTypes';


const INIT_STATE = {
    
    loading: false

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case LOADING:

            return {

                ...state,

                loading: action.payload,
            };

        default:
            
            return state;

    }

}