import {

    LOADING

} from '../actionTypes';


export function showLoading(data) {

    return {

        type: LOADING,

        payload: data
    }; 

}

