
import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import CalendarEvent from 'components/calendarEvent'
import {
    showLoading
} from 'store/actions'
import {
    searchClubEvents,
} from 'apis'
import './index.scss'

const localizer = momentLocalizer(moment)



class ClubEventsCalendar extends Component {

    constructor(props) {

        super(props)

        this.state = {

            ID: this.props.match.params.ID,

            minTime: moment("05:00 AM", "HH:mm A").toDate(),

            maxTime: moment("11:00 PM", "HH:mm A").toDate(),

            events: []
        };

        this.debounceId = null;
        
        this.eventStyleGetter = this.eventStyleGetter.bind(this)

        this.onCalendarNavigate = this.onCalendarNavigate.bind(this)

        this.selectEvent = this.selectEvent.bind(this)
    }

    componentDidMount(){

        if (!this.state.ID) return;

        const params = {

            ID: this.state.ID,
            
            stDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss A'),

            etDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss A')

        }

        this.search(params)
    }

    search(params){

        if (this.props.loading) return;

        this.props.showLoading(true);

        searchClubEvents(params).then(data => {

            this.props.showLoading(false)

            if (data.code === 200){
                
                this.setState({

                    events: data.data.map(evt => {

                        evt.start = moment(evt.start, 'YYYY-MM-DD HH:mm:ss A').toDate();

                        evt.end = moment(evt.end, 'YYYY-MM-DD HH:mm:ss A').toDate()

                        return evt;
                    }),
                })

            }
        }).catch(error => {
            
            this.props.showLoading(false);
        })
    }

    
    eventStyleGetter(event, start, end, isSelected){

        const backgroundColor = event.displayColor;

        const style = {

            backgroundColor: backgroundColor,

            border: 'none', 

            borderRadius: '5px',

            color: 'white',

            display: 'block'
        };

        return { style: style };
    }

    selectEvent(event){
        
        window.open(`${process.env.REACT_APP_FRONT_URL}/${this.state.ID}/clubevent/list`, '__black')
    }

    debounceSearch( func, delay){

        if (this.debounceId) clearTimeout(this.debounceId);

        this.debounceId = setTimeout(func, delay)
    }


    onCalendarNavigate(date, view, action){

        let params = { ID: this.state.ID }

        if (view === 'week'){

            params = {...params, 

                stDate: moment(date).startOf('weeks').format('YYYY-MM-DD HH:mm:ss A'),

                etDate: moment(date).endOf('weeks').format('YYYY-MM-DD HH:mm:ss A')
            }

        }else if (view === 'day'){

            params = {...params, 

                stDate: moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss A'),

                etDate: moment(date).add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss A')
            }
        }

        this.debounceSearch(()=>{this.search(params)}, 300)
    }

    render() {

        if (!this.state.ID) return null;

        return (

            <Calendar
                localizer={localizer}
                selectable
                step={30}
                timeslots={2}
                views={['week', 'day']}
                events={this.state.events}
                min={this.state.minTime}
                max={this.state.maxTime}
                defaultView='week'
                scrollToTime={new Date(2019, 0, 0, 0)}
                defaultDate={new Date()}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={this.selectEvent}
                eventPropGetter={(this.eventStyleGetter)}
                components={{
                    event: CalendarEvent
                }}
                onNavigate={this.onCalendarNavigate}
            />
        )
    }
}


const mapStateToProps = (reducer) => {

    const {loading} = reducer;

    return {loading};
}

export default connect(mapStateToProps, {

    showLoading
    
})(ClubEventsCalendar)