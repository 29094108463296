import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import Routes from './routes'

class Container extends React.Component {

    constructor(props){

        super(props);

        this.state = {
         
        }

    }

    render() {

        return (

           <Switch>
               <Route path='/' component={Routes}/>
           </Switch>

        );

    }

}


const mapStateToProps = (reducer) => {

    const { loading } = reducer;

    return {loading}

};


export default connect(mapStateToProps, {})(Container);