import axios from 'axios';

axios.interceptors.response.use( (response) => {
    return response;
}, (error) => {

    if (error.response) {

        if (error.response && error.response.status === 400){
            
        }
    
        if (process.env.NODE_ENV === "development"){

            console.log(error.response)
        }
        
        return Promise.reject(error);
        
    } else {
        
        return Promise.reject({
            response: {
                data: {code: 320, message: 'Network connection lost'}}
            }
        )
    }

});


export * from './calendar'