import React from 'react';
import {withRouter} from 'react-router-dom'
import { Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import ClubEventCalendar from './calendar'
import FullLoader from 'components/fullLoader'
import {
    showLoading
} from 'store/actions';


class Routes extends React.Component {

    constructor(props){

        super(props);

        this.state = {

        }
    }

    render() {

        return (
            <div id="route-container">
                <div id="route-content">
                    <Switch>
                        <Route path='/calendar/:ID' component={ClubEventCalendar}/> 
                    </Switch>
                    <FullLoader loading={this.props.loading}/>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (reducer) => {
    
    const { loading} = reducer;

    return {loading}
};

export default withRouter(connect(mapStateToProps, {

    showLoading

})(Routes));