export default {

    APP_NAME: 'EMBEDED_CALENDAR',


    VENUE_TYPE: {

        REAL: 0,

        VIRTUAL: 1
    }
}
