import React, { Component } from 'react'
import constants from 'utils/constants'

class CalendarEvent extends Component {

    constructor(props) {

        super(props)
    
        this.state = { }
    }
    
   
    render() {

        return (

            <React.Fragment>
                <p className="mb-1">{this.props.event.title}</p>
                {
                    this.props.event.venue &&
                    <React.Fragment>
                        <p className="mb-1">{this.props.event.venue.displayName}</p>
                        {
                            this.props.event.venue.type === constants.VENUE_TYPE.REAL ?
                            <p className="mb-1">{!this.props.event.court  ? "" : `Court ${this.props.event.court + 1}`}</p> :
                            <p className="mb-1">{!this.props.event.location ? "" : this.props.event.location}</p>
                        }
                    </React.Fragment>
                }
                <p>{!this.props.event.sponsor || this.props.sponsor === "" ? "" : this.props.sponsor}</p>
            </React.Fragment>

        )
    }
}



export default CalendarEvent;

